import styles from './Logos.module.scss';

import { findRegionByCountryCode } from '@/utilities/helpers/Region';
import { useCountry } from '@/utilities/contexts/CountryContext';
import cn from 'classnames';
import Image from 'next/image';
import typeAlignment from '@/utilities/types/Alignment';
import typeImage from '@/utilities/types/Image';

interface LogosProps {
  className?: string;
  customise: {
    alignment: typeAlignment['alignment'];
    grayscale: boolean;
    size: 'medium' | 'large';
  };
  gap: 'small' | 'medium';
  logos: {
    region?: string[];
    logo?: typeImage;
  }[];
}

const Logos = (props: LogosProps) => {
  const { className, customise, gap, logos } = props;

  const country = useCountry();
  const region = findRegionByCountryCode(country);

  const logosRegional =
    (logos &&
      logos.filter(
        (logo) => logo.region?.includes('all') || logo.region?.includes(region),
      )) ||
    logos;

  return (
    <div
      className={cn(
        className,
        customise.grayscale && styles.grayscale,
        styles.root,
        styles[customise.alignment],
        styles[gap],
        {
          [styles[`size-${customise.size}` ?? '']]: customise.size,
        },
      )}
    >
      <ul>
        {logosRegional &&
          logosRegional.map((logo, index) => {
            return logo.logo ? (
              <li key={index}>
                <i className={styles.logo}>
                  <Image
                    alt={logo.logo.altText}
                    height={logo.logo.mediaDetails.height || 64}
                    src={logo.logo.mediaItemUrl}
                    width={logo.logo.mediaDetails.width || 176}
                  />
                </i>
              </li>
            ) : null;
          })}
      </ul>
    </div>
  );
};

export default Logos;
